import React, {useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from "axios";
import { AUTH_ENDPOINT } from "../consts";

const baseCustomerUrl = "https://socolife.app/_customers/";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1
  },
  signInContainer: {
    display: "flex",
    maxWidth: 400,
    width: "100%",
    height: "100vh",
    backgroundColor: "#fff",
    paddingLeft: 40,
    paddingRight: 40,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative"
  },
  imagesContainer: {
    display: "flex",
    flex: 1,
    backgroundColor: "#00ff00000",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    position: "relative",
    width: "100%"
  },
  signInButton: {
    marginTop: 40,
    width: "100%"
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: 40,
    left: 0
  },
  logo: {
    width: "70%",
    height: "auto"
  }
}));

export const SignIn = (props) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { innerWidth, innerHeight } = window;

  const signIn = () => {
    axios.post(AUTH_ENDPOINT+'?func=soco_authenticate', {
      username: username,
      password: password
    },{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(res => {
      if(res.status === 200 && res.data !== "") {
        localStorage.setItem('signedIn', true);
        props.saveCustomerDetails({
          id_customer: res.data.id_customer,
          id_user: res.data.id_user,
          is_admin: res.data.is_admin,
          name: res.data.name,
          username: res.data.username,
          color: res.data.brand_color,
          redirect: baseCustomerUrl + res.data.redirect + "?id_customer="+ res.data.id_customer +"&id_user="+ res.data.id_user +"&is_admin="+ res.data.is_admin,
        });
        props.setIsAuthenticated(true);
      } else {
        alert("Sign in failed");
      }
    });
  }

  return (
    <div className={classes.container}>
      <div className={classes.signInContainer}>
        <div className={classes.logoContainer}>
          <img src="images/logo_black.png" className={ classes.logo } />
        </div>
        <TextField
          name="username"
          id="username" 
          label="Username" 
          variant="standard" 
          className={classes.title} 
          onChange={e => {
            setUsername(e.target.value);
          }}
          value={username}
        />
        <TextField
          name="password"
          id="password" 
          label="Password" 
          type="password"
          variant="standard" 
          className={classes.title} 
          style={{ top: 10 }}
          onChange={e => {
            setPassword(e.target.value);
          }}
          value={password}
        />
        <div className={classes.signInButton}>
          <Button style={{width: "100%"}} variant="contained" onClick={() => { signIn(); }}>Sign In</Button>
        </div>
      </div>
      <div className={classes.imagesContainer} style={{ backgroundImage: 'url(images/bg2.jpg)' }}>

      </div>
    </div>
  );
  
}